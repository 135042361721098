import React, { useState, useEffect } from 'react';
// import Main from "./components/pages/main";

function App() {
//   // const [error, setError] = useState(null);
//   // const [isLoaded, setIsLoaded] = useState(false);
//   const [resumeData, setResumeData] = useState([]);

//   const getData = async () => {
//     fetch('resumeData.json'
//       , {
//         headers: {
//           'Content-Type': 'application/json',
//           'Accept': 'application/json'
//         }
//       })
//       .then(response => response.json())
//       .then(function (data) {
//         setResumeData(data)
//       });
//   }

//   useEffect(() => {
//     getData()
//   }, [])

//   return <Main data={resumeData} />;
    return <div>This is a work in progress...</div>
}

 // NOTE: RESUME.PDF FILE NEEDS TO BE ADDED BACK IN THE `src` FOLDER ONCE IT"S READY TO BE ADDED BACK

export default App;
